import React from 'react';
import Convert from '../../Components/Convert/Convert';
import CreateProduct from '../../Components/CreateProduct/CreateProduct';

function UploadProduct() {
    return ( <>
        <div>
            <Convert></Convert>
            <CreateProduct></CreateProduct>

        </div>
        
    </> );
}

export default UploadProduct;